/* 
* constants, required for the import within constants.module.scss,
* to overwrite default constants for customization
*/

// Gelbgrün laut Styleguide
$color-primary: #C4D20F;
// Apfelgrün laut Styleguide
$color-primary-gradient: #95C11F;
// 15% von Gelbgrün
$color-primary-light: #F3F6DE;

// color variables need to be copied here from constants.module.scss for import order (when needed for variables below), to be marked as copy
// $color-dark: #292929 !default; // copy of constants.module.scss

$defaultLogo: "/assets/img/uni-kassel/logo-uni-kassel-greenoffice.svg";
// $faviconCode: svg-encode(str-replace($mobileLogoAndFaviconSVG, "currentColor", $color-primary)) !default;
// by default: mobile-logo and favicon are the same. Overwrite for customer mobile-logo.
$mobileLogoCodeOrUrl: $defaultLogo;

// If the costumer favicon is not embeddable an URL of a favicon may be provided here.
// NOTE: url is preferred to svg code, so it must be set to null if embedded SVG code should be used
// It may be overwritten by customers favicon svg.
//$faviconUrl: null !default; // null means: use the favicon SVG code instead an external file (e.g. "/client-favicon.png") -> handled by BaseLayout.tsx