/* custom logo + mobile logo*/
header>.row .header-logo {
  width: 120px;

  @media screen and (min-width: $lg) {
    width: 150px;
  }
}

// start custom frontpage
.frontpage {
  background-image: url("/assets/img/uni-kassel/uni-kassel_bg.png"), url("/assets/img/city_rotate.svg"), $background-image;
  background-size: 30% auto, 100% 140%, auto;
  background-position-y: bottom, 5vh, 0;
  background-position-x: right, 40vw, 0;

  header {
    background-color: $color-white;
  }


  .frontpage-text {
    max-width: 25rem;
    margin-bottom: 2rem;
  }

  .btn-frontpage {
    margin-top: 2rem;
    color: $color-dark !important;
    background-image: none;
    background-color: $color-primary;
    margin: auto;
  }
}

// start custom buttons
.primary-btn {
  background-image: none;
  background-color: $color-primary;
}

// start custom market-place
.market-info-card {

  &:after {
    background-image: none;
    background-color: #2C89A4;
  }
}

// activate hyphenation for every page, requested by Kassel
// @see https://futureprojects.atlassian.net/browse/FCP-1825
body {
  hyphens: auto;
}